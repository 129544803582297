<template>
  <div id="app">
    <div class="max-w-3xl mx-auto p-5 outline-hidden">
      <div class="flex justify-between items-center">
        <a href="/" class="text-xl font-bold hover:text-red-500">小米水印生成器</a>
        <div class="text-gray-300 font-bold">
          by
          <a
            href="https://linktr.ee/lvwzhen"
            target="_blank"
            class="text-black text-lg hover:text-red-500 ml-1"
            >lvwzhen</a
          >
        </div>
      </div>
      <div class="-mx-5 my-5 md:mx-0 shadow-sm relative group">
        <a
          class="absolute top-5 z-50 right-5 bg-white py-1 px-3 rounded-full transition-all md:opacity-0 group-hover:md:opacity-100"
          v-if="camImg"
          @click="clearDate"
          >重新上传</a
        >
        <div id="camMaker">
          <div class="bg-white dark:bg-black">
            <div
              v-if="camImg == null"
              @click="selectImage"
              class="w-full h-60 md:h-80 cursor-pointer relative"
            >
              <div
                class="absolute left-4 top-4 right-4 bottom-4 md:left-8 md:top-8 md:right-8 md:bottom-8 border-2 border-dashed border-gray-200 hover:border-gray-400 transition-all rounded-lg text-gray-400 text-xl items-center flex justify-center"
              >
                <span>上传图片</span>
              </div>
            </div>
            <div>
              <figure :class="'filter-' + filter">
                <img v-if="camImg" class="block align-top w-full" :src="camImg" ref="camImg" />
              </figure>
            </div>
            <div class="flex justify-between p-4 md:p-8 leading-none dark:text-white">
              <div>
                <h3 class="font-medium text-xs md:text-[22px] mb-1 md:mb-3">
                  {{ modal }}
                </h3>
                <p class="opacity-40 dark:opacity-80 text-[8px] md:text-base">
                  {{ time }}
                </p>
              </div>
              <div class="flex items-center gap-[6px] md:gap-3">
                <img :src="brand" class="w-6 h-6 md:w-12 md:h-12 object-cover" alt="" />
                <div class="w-[1px] h-6 md:h-12 bg-[#D8D8D8]"></div>
                <div>
                  <h3
                    class="font-medium text-xs md:text-[22px] mb-1 md:mb-3 flex gap-[6px] md:gap-3"
                  >
                    <span>{{ exif.focalLength }}</span
                    ><span>{{ exif.fStop }}</span
                    ><span>{{ exif.exposureTime }}</span
                    ><span>{{ exif.iso }}</span>
                  </h3>
                  <p
                    class="opacity-40 dark:opacity-80 text-[8px] md:text-base flex gap-[6px] md:gap-3"
                  >
                    <span>{{ lat }}</span
                    ><span>{{ lon }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <input ref="fileInput" type="file" accept="image/*" class="hidden" @input="pickFile" />
      <div class="text-center my-5" v-if="camImg">
        <button
          class="bg-red-500 text-white block py-2 w-full rounded-full"
          @click="generatorImageDialog"
          v-if="Mobile"
        >
          下载图片
        </button>
        <button
          v-else
          class="bg-red-500 text-white block py-2 w-full rounded-full"
          @click="generatorImage"
        >
          下载图片
        </button>
      </div>
      <van-cell-group>
        <van-field
          v-model="modal"
          label="型号"
          placeholder="输入框内容右对齐"
          input-align="right"
        />
        <van-cell title="图标">
          <template #right-icon>
            <div class="flex-1 flex items-center">
              <select
                name=""
                id=""
                class="w-full text-right leading-6 appearance-none bg-transparent"
                @change="changeBrand"
              >
                <option value="0">Leica</option>
                <option value="1">小米</option>
                <option value="2">Apple</option>
                <option value="3">华为</option>
                <option value="4">三星</option>
                <option value="5">OPPO</option>
                <option value="6">vivo</option>
                <option value="7">Realme</option>
                <option value="8">佳能</option>
                <option value="9">尼康</option>
                <option value="10">索尼</option>
                <option value="11">松下</option>
              </select>
              <van-icon class="ml-1" color="#999" name="arrow" />
            </div>
          </template>
        </van-cell>
        <van-cell title="滤镜">
          <template #right-icon>
            <div class="flex-1 flex items-center">
              <select
                name=""
                id=""
                class="w-full text-right leading-6 appearance-none bg-transparent"
                @change="changeFilter"
              >
                <option value="">Normal</option>
                <option value="clarendon">Clarendon</option>
                <option value="juno">Juno</option>
                <option value="ludwig">Ludwig</option>
                <option value="lark">Lark</option>
                <option value="gingham">Gingham</option>
                <option value="lofi">Lo-fi</option>
                <option value="aden">Aden</option>
                <option value="valencia">Valencia</option>
                <option value="xpro-ii">X-Pro II</option>
              </select>
              <van-icon class="ml-1" color="#999" name="arrow" />
            </div>
          </template>
        </van-cell>
        <van-cell title="主题">
          <template #right-icon>
            <div class="flex-1 flex items-center">
              <select
                name=""
                id=""
                class="w-full text-right leading-6 appearance-none bg-transparent"
                @change="changeTheme"
              >
                <option value="light">浅色</option>
                <option value="dark">深色</option>
              </select>
              <van-icon class="ml-1" color="#999" name="arrow" />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="mt-10 mb-5 text-center">
        <div class="my-10 text-center w-full">
          <h2 class="text-slate-500">更多好玩</h2>
          <ul class="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 mt-4 mx-auto text-xs">
            <li>
              <a
                href="https://magickpen.com/?ref=mi-cam"
                class=" text-slate-400 border border-slate-300/20 transition-all hover:bg-white hover:backdrop-blur-md py-2.5 rounded-md block"
              >
                <img
                  src="/MagickPen.png"
                  width="100"
                  height="20"
                  class="w-full mb-1 h-5 object-contain"
                  alt="MagickPen - 智能写作助手"
                />
                智能写作助手
              </a>
            </li>
            <li>
              <a
                href="https://www.teach-anything.com/?ref=mi-cam"
                class="text-slate-400 border border-slate-300/20 transition-all hover:bg-white hover:backdrop-blur-md py-2.5 rounded-md block"
              >
                <img
                  src="/TeachAnything.png"
                  width="100"
                  height="20"
                  class="w-full mb-1 h-5 object-contain"
                  alt="Teach Anything - AI 百科全书"
                />
                AI 百科全书
              </a>
            </li>
            <li>
              <a
                href="https://openl.io/?ref=mi-cam"
                class="text-slate-400 border border-slate-300/20 transition-all hover:bg-white hover:backdrop-blur-md py-2.5 rounded-md block"
              >
                <img
                  src="/OpenL.png"
                  width="100"
                  height="20"
                  class="w-full mb-1 h-5 object-contain"
                  alt="OpenL - AI 翻译专家"
                />
                AI 翻译专家
              </a>
            </li>
            <li>
              <a
                href="https://law-cn-ai.vercel.app/?ref=mi-cam"
                class="text-slate-400 border border-slate-300/20 transition-all hover:bg-white hover:backdrop-blur-md py-2.5 rounded-md block"
              >
                <img
                  src="/law-ai.png"
                  width="100"
                  height="20"
                  class="w-full mb-1 h-5 object-contain"
                  alt="AI 法律助手"
                />
                AI 法律助手
              </a>
            </li>
          </ul>
        </div>

        <p class="text-xs text-gray-400">
          免责声明：本网站仅供学习参考，如有侵权，请联系我们删除。请遵守当地法律法规，违者后果自负。
        </p>
      </div>
      <van-action-sheet
        v-model="previewPopup"
        title="长按保存图片"
        cancel-text="关闭"
        :closeable="false"
      >
        <div class="p-4 bg-[#fafafa]">
          <img :src="previewImage" alt="" />
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ActionSheet } from "vant";
import Vue from "vue";
import domtoimage from "dom-to-image";
import moment from "moment";
import EXIF from "exif-js";
Vue.use(Toast);
Vue.use(ActionSheet);

export default {
  components: {},
  data() {
    return {
      previewPopup: false,
      modal: "XIAOMI 12S ULTRA",
      time: "2022.07.01 18:58:13",
      exif: {
        focalLength: "120mm",
        fStop: "f/4.1",
        exposureTime: "1/100",
        iso: "ISO90",
      },
      lat: "40°3'13\"N",
      lon: "116°19'25\"E",
      camImg: null,
      scale: 1,
      camMakerImg: null,
      msg: "小米水印生成器 https://cam.lvwzhen.com/",
      previewImage: null,
      Mobile: false,
      disabled: false,
      brandList: [
        {
          url: require("./assets/brand/1.png"),
        },
        {
          url: require("./assets/brand/2.png"),
        },
        {
          url: require("./assets/brand/3.png"),
        },
        {
          url: require("./assets/brand/4.png"),
        },
        {
          url: require("./assets/brand/5.png"),
        },
        {
          url: require("./assets/brand/6.png"),
        },
        {
          url: require("./assets/brand/7.png"),
        },
        {
          url: require("./assets/brand/8.png"),
        },
        {
          url: require("./assets/brand/9.png"),
        },
        {
          url: require("./assets/brand/10.png"),
        },
        {
          url: require("./assets/brand/11.png"),
        },
        {
          url: require("./assets/brand/12.png"),
        },
      ],
      brand: require("./assets/brand/1.png"),
      filter: "",
      projects: [
        {
          url: "https://mp.weixin.qq.com/s/ei9CYh6zZ5co0d6hP78RSA",
          img: "https://cam.lvwzhen.com/project/coldplay-weekly.png",
        },
        {
          url: "weixin://dl/business/?t=p0pwI8Z8ljm",
          img: "https://cam.lvwzhen.com/project/1024.png",
        },
        {
          url: "https://www.zhihu.com/column/c_1496834279112400896",
          img: "https://cam.lvwzhen.com/project/apple-wiki.png",
        },
        {
          url: "https://bdd2022.com/",
          img: "https://cam.lvwzhen.com/project/bdd2022.png",
        },
        {
          url: "https://cam.lvwzhen.com/",
          img: "https://cam.lvwzhen.com/project/mi-cam.png",
        },
        {
          url: "https://mi-logo.lvwzhen.com/",
          img: "https://cam.lvwzhen.com/project/mi-logo.png",
        },
        {
          url: "https://sharenote.app/",
          img: "https://cam.lvwzhen.com/project/share-note.png",
        },
      ],
      selected: null,
    };
  },
  created() {
    this.initTime();
    this.selected = this.randomProject(this.projects);
  },
  methods: {
    randomProject(projects) {
      return projects[Math.floor(Math.random() * projects.length)];
    },
    initTime() {
      this.time = moment().format("YYYY.MM.DD HH:mm:ss");
    },
    clearDate() {
      this.camImg = null;
      this.modal = "XIAOMI 12S ULTRA";
      this.time = "2022.07.01 18:58:13";
      this.exif = {
        focalLength: "120mm",
        fStop: "f/4.1",
        exposureTime: "1/100",
        iso: "ISO90",
      };
      this.lat = "40°3'13\"N";
      this.lon = "116°19'25\"E";
      this.brand = require("./assets/brand/1.png");
    },
    share: function () {
      this.$copyText(this.msg).then(
        function () {
          Toast("复制成功，快去分享吧！");
        },
        function () {
          Toast("复制失败");
        }
      );
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 1000);
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.camImg = e.target.result;
          Toast.clear();
          setTimeout(() => {
            this.getImgInfo();
          }, 1000);
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    f(x) {
      var y = 1 / x;
      return Math.round(y);
    },
    getImgInfo() {
      let _this = this;
      EXIF.getData(this.$refs.camImg, function () {
        const imgAllInfo = EXIF.getAllTags(this);
        if (imgAllInfo.Model) {
          _this.modal = imgAllInfo.Model;
        }
        if (imgAllInfo.DateTime) {
          _this.time = moment(imgAllInfo.DateTime, "YYYY:MM:DD HH:mm:ss").format(
            "YYYY.MM.DD HH:mm:ss"
          );
        }
        if (imgAllInfo.FocalLengthIn35mmFilm) {
          _this.exif.focalLength = imgAllInfo.FocalLengthIn35mmFilm + "mm";
        } else if (imgAllInfo.FocalLength) {
          _this.exif.focalLength = imgAllInfo.FocalLength + "mm";
        }
        if (imgAllInfo.FNumber) {
          _this.exif.fStop = "f/" + imgAllInfo.FNumber;
        }
        if (imgAllInfo.ExposureTime) {
          var time = imgAllInfo.ExposureTime.numerator / imgAllInfo.ExposureTime.denominator;
          if (time < 1) {
            _this.exif.exposureTime = "1/" + _this.f(time);
          } else {
            _this.exif.exposureTime = time;
          }
        }
        if (imgAllInfo.ISOSpeedRatings) {
          _this.exif.iso = "ISO" + imgAllInfo.ISOSpeedRatings;
        }
        const imgLon = EXIF.getTag(this, "GPSLongitude");
        const imgLonRef = EXIF.getTag(this, "GPSLongitudeRef");
        const imgLat = EXIF.getTag(this, "GPSLatitude");
        const imgLatRef = EXIF.getTag(this, "GPSLatitudeRef");
        if (imgLon && imgLat) {
          const lon = imgLon[0] + "°" + imgLon[1] + "′" + imgLon[2].toFixed(0) + "″";
          const lat = imgLat[0] + "°" + imgLat[1] + "′" + imgLat[2].toFixed(0) + "″";
          _this.lat = lat + imgLatRef;
          _this.lon = lon + imgLonRef;
        }
      });
    },
    changeBrand(e) {
      this.brand = this.brandList[e.target.value].url;
    },
    changeTheme(e) {
      if (e.target.value == "dark") {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    },
    // changeFilter
    changeFilter(e) {
      this.filter = e.target.value;
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    maker() {
      var node = document.getElementById("camMaker");
      var that = this;
      const scale = 2;
      const style = {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px",
      };
      const param = {
        height: node.offsetHeight * scale,
        width: node.offsetWidth * scale,
        quality: 1,
        style,
      };
      domtoimage.toPng(node, param).then(function () {
        domtoimage.toPng(node, param).then(function (dataUrl) {
          that.previewImage = dataUrl;
          Toast.clear;
          that.previewPopup = true;
        });
      });
    },
    generatorImageDialog() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      this.maker();
    },
    downloadImage(imgsrc, name) {
      //下载图片地址和图片名
      let image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL("image/png");
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = name || "photo";
        a.href = url;
        a.dispatchEvent(event);
      };
      image.src = imgsrc;
    },
    generatorImage() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      setTimeout(() => {
        Toast.clear;
      }, 300);
      var that = this;
      var node = document.getElementById("camMaker");
      const scale = 2;
      const style = {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: node.offsetWidth + "px",
        height: node.offsetHeight + "px",
      };
      const param = {
        height: node.offsetHeight * scale,
        width: node.offsetWidth * scale,
        quality: 1,
        style,
      };
      domtoimage.toPng(node, param).then(function () {
        domtoimage.toPng(node, param).then(function (dataUrl1) {
          that.downloadImage(dataUrl1, "cam.png");
        });
      });
    },
  },
  mounted() {
    if (this.isMobile()) {
      this.Mobile = true;
    } else {
      this.Mobile = false;
    }
  },
};
</script>

