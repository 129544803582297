import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import VueClipboard from "vue-clipboard2";

import "vant/lib/index.css";
import "./assets/style/instagram.min.css";
import "./assets/style/style.css";

Vue.use(Vant);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");